import axios from "axios";

const passwordpolicy = {
  actions: {
    processpasswordpolicy(context, userdata) {
      return new Promise((resolve, reject) => {
        axios({
          url: "/PasswordPolicy/ProcessPasswordPolicy",
          data: userdata,
          method: "POST",
        })
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getpasswordpolicy() {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/PasswordPolicy/GetPasswordPolicy`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },   
  },
};

export default passwordpolicy;
