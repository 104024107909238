import axios from 'axios';

const merchandiseroutlet = {   
	actions: {
		getmerchandiseroutletcode({rootState}){
			return new Promise((resolve, reject) => {				
				axios.get('/MerchandiserOutlet/GetMerchandiserOutletCode?Username='+ rootState.auth.token)
				.then(resp => resp.data)
				.then(respdata => {													
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
		getmerchandiseroutlet({rootState},UserId){
			return new Promise((resolve, reject) => {				
				axios.get(`/MerchandiserOutlet/GetMerchandiserOutlet?UserId=${UserId}&Username=${rootState.auth.token}`)
				.then(resp => resp.data)
				.then(respdata => {													
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
		getmerchandiseroutletforkpiperformance({rootState},KpiReferenceId){
			return new Promise((resolve, reject) => {				
				axios.get(`/MerchandiserOutlet/GetMerchandiserOutletforKpiPerfomance?KpiReferenceId=${KpiReferenceId}&Username=${rootState.auth.token}`)
				.then(resp => resp.data)
				.then(respdata => {													
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
        getmerchandiseroutletbyid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios.get(`/MerchandiserOutlet/GetMerchandiserOutletById?Id=${Id}&Username=${rootState.auth.token}`)
				.then(resp => resp.data)
				.then(respdata => {								
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },			
		searchmerchandiseroutlet({rootState}, item){
			return new Promise((resolve, reject) => {				
				axios.get(`/MerchandiserOutlet/SearchMerchandiserOutlet?SearchItem=${item}&Username=${rootState.auth.token}`)
				.then(resp => resp.data)
				.then(respdata => {										
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},
        deletemerchandiseroutlet({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios.get(`/MerchandiserOutlet/DeleteMerchandiserOutlet?Id=${Id}&Username=${rootState.auth.token}`)
				.then(resp => resp.data)
				.then(respdata => {								
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },		
		processmerchandiseroutlet(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/MerchandiserOutlet/ProcessMerchandiserOutlet', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
        },        
	}
}

export default merchandiseroutlet