import axios from "axios";

const channelproduct = {
  actions: {
    getchannelproduct({ rootState },{KpiRefenceId,ChannelId} ) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/ChannelProduct/GetChannelProduct?KpiRefenceId=${KpiRefenceId}&ChannelId=${ChannelId}&Username=${rootState.auth.token}`)
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },   
    DeleteChannelProduct({ rootState }, Id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/ChannelProduct/DeleteChannelProduct?Id=${Id}&Username=${rootState.auth.token}`)
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },   
    SetChannelProductPromo({ rootState }, Id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/ChannelProduct/SetChannelProductPromo?Id=${Id}&Username=${rootState.auth.token}`)
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },  
    SetChannelProductNpd({ rootState }, Id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/ChannelProduct/SetChannelProductNpd?Id=${Id}&Username=${rootState.auth.token}`)
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },     
    processchannelproduct(context, userdata) {
      return new Promise((resolve, reject) => {
        axios({
          url: "/ChannelProduct/ProcessChannelProduct",
          data: userdata,
          method: "POST",
        })
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },    
  },
};

export default channelproduct;
