<template>
<nav class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white" id="sidenav-main">
    <div class="container-fluid">

        <!--Toggler-->
        <navbar-toggle-button @click.native="showSidebar">
            <span class="navbar-toggler-icon"></span>
        </navbar-toggle-button>
        <router-link class="navbar-brand" to="/">
            <img class="navbar-brand-img" alt="" :src="logo" />           
        </router-link>

        <slot name="mobile-right">
            <ul class="nav align-items-center d-md-none">
                <base-dropdown class="nav-item" position="right">
                    <a slot="title" class="nav-link" href="#" role="button">
                        <div class="media align-items-center">
                            <span class="avatar avatar-sm rounded-circle">                                
                                <img v-if="$store.state.auth.user_passport" class="navbar-brand-img" alt="" :src="$store.state.auth.user_passport" />
                                <img v-else class="navbar-brand-img" src="img/brand/loguser.png" alt="" />
                            </span>
                        </div>
                    </a>

                    <div class=" dropdown-header noti-title">
                        <h6 class="text-overflow m-0">Welcome!</h6>
                    </div>
                    <div class="dropdown-divider"></div>
                     <router-link to="/profile" class="dropdown-item"> <i class="fa fa-user"></i>
                        <span>Profile</span></router-link>
                    <div class="dropdown-divider"></div>
                     <button class="dropdown-item" @click="logout">
                         <i class="ni ni-user-run"></i>
                        <span>Logout</span>
                    </button>
                </base-dropdown>
            </ul>
        </slot>
        <slot></slot>
        <div v-show="$sidebar.showSidebar" class="navbar-collapse collapse show" id="sidenav-collapse-main">
            <div class="navbar-collapse-header d-md-none">
                <div class="row">
                    <div class="col-6 collapse-brand">
                        <router-link to="/">
                            <img alt="" :src="logo" />                           
                        </router-link>
                    </div>
                    <div class="col-6 collapse-close">
                        <navbar-toggle-button @click.native="closeSidebar"></navbar-toggle-button>
                    </div>
                </div>
            </div>

            <ul class="navbar-nav">
                <slot name="links">
                </slot>
            </ul>
        </div>
    </div>
</nav>
</template>

<script>
import NavbarToggleButton from '@/components/NavbarToggleButton'
import {
    mapState
} from 'vuex'

export default {
    name: 'sidebar',
    components: {
        NavbarToggleButton
    },
    props: {
        logo: {
            type: String,
            default: 'img/brand/green.png',
            description: 'Sidebar app logo'
        },
        autoClose: {
            type: Boolean,
            default: true,
            description: 'Whether sidebar should autoclose on mobile when clicking an item'
        }
    },
    provide() {
        return {
            autoClose: this.autoClose
        };
    },
    computed: {
        ...mapState({
            userdetails: state => state.auth.userdetails,           
            token: state => state.auth.token
        })
    },
    methods: {
        logout: function () {
            this.$store.dispatch('logout')
                .then(() => {
                    this.$router.push('/login')
                })
        },
        closeSidebar() {
            this.$sidebar.displaySidebar(false)
        },
        showSidebar() {
            this.$sidebar.displaySidebar(true)
        }
    },
    beforeDestroy() {
        if (this.$sidebar.showSidebar) {
            this.$sidebar.showSidebar = false;
        }
    }
};
</script>
