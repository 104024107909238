import "@/assets/vendor/nucleo/css/nucleo.css";
import "@/assets/slider.css";
import "@/assets/spinner.css";
import "@/assets/scss/sdock.scss";
import globalComponents from "./globalComponents";
import globalDirectives from "./globalDirectives";
import SidebarPlugin from "@/components/SidebarPlugin/index"
import NotificationPlugin from "@/components/NotificationPlugin/index"
import CKEditor from 'ckeditor4-vue'


export default {
  install(Vue) {
    Vue.use(require('vue-moment'));
    Vue.use(CKEditor);    
    Vue.use(globalComponents);
    Vue.use(globalDirectives);
    Vue.use(SidebarPlugin);
    Vue.use(NotificationPlugin);

  }
};
