import axios from 'axios';

const region = {   
	actions: {
		getregion(){
			return new Promise((resolve, reject) => {				
				axios.get('/Region/GetRegion')
				.then(resp => resp.data)
				.then(respdata => {													
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
       
        getregionbyid(context,Id){
			return new Promise((resolve, reject) => {				
				axios.get(`/Region/GetRegionById?Id=${Id}`)
				.then(resp => resp.data)
				.then(respdata => {								
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },		
		searchregion({rootState}, item){
			return new Promise((resolve, reject) => {				
				axios.get(`/Region/SearchRegion?SearchItem=${item}&Username=${rootState.auth.token}`)
				.then(resp => resp.data)
				.then(respdata => {										
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
		processregion(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Region/ProcessRegion', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
        },        
	}
}

export default region