import axios from 'axios';

const distributor = {   
	actions: {
		getdistributor(){
			return new Promise((resolve, reject) => {				
				axios.get('/Distributor/GetDistributor')
				.then(resp => resp.data)
				.then(respdata => {													
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
       
        getdistributorbyid(context,Id){
			return new Promise((resolve, reject) => {				
				axios.get(`/Distributor/GetDistributorById?Id=${Id}`)
				.then(resp => resp.data)
				.then(respdata => {								
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },	
		getdistributorbyregion(context,RegionId){
			return new Promise((resolve, reject) => {				
				axios.get(`/Distributor/GetDistributorByRegion?RegionId=${RegionId}`)
				.then(resp => resp.data)
				.then(respdata => {								
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },		
		searchdistributor({rootState}, item){
			return new Promise((resolve, reject) => {				
				axios.get(`/Distributor/SearchDistributor?SearchItem=${item}&Username=${rootState.auth.token}`)
				.then(resp => resp.data)
				.then(respdata => {										
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
		processdistributor(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Distributor/ProcessDistributor', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
        },        
	}
}

export default distributor