<template>
  <base-nav
    class="navbar-top navbar-dark"
    id="navbar-main"
    :show-toggle-button="false"
    expand
  >
    <form
      class="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto"
    ></form>
    <ul class="navbar-nav align-items-center d-none d-md-flex">
      <li class="nav-item dropdown">
        <base-dropdown class="nav-link pr-0">
          <a href="#" class="media align-items-center" slot="title">
            <span class="avatar avatar-sm rounded-circle">
              <img
                v-if="$store.state.auth.user_passport"
                alt=""
                :src="$store.state.auth.user_passport"
              />
              <img v-else src="img/brand/loguser.png" alt="" />
            </span>
            <div class="media-body ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm text-white font-weight-bold">{{
                $store.state.auth.user_fullName
              }}</span>
            </div>
          </a>
          <template>
            <div class=" dropdown-header noti-title">
              <h6 class="text-overflow m-0">Welcome!</h6>
            </div>
            <div class="dropdown-divider"></div>
            <router-link to="/profile" class="dropdown-item">
              <i class="fa fa-user"></i> <span>Profile</span></router-link
            >
            <div class="dropdown-divider"></div>
            <button class="dropdown-item" @click="logout">
              <i class="ni ni-user-run"></i>
              <span>Logout</span>
            </button>
          </template>
        </base-dropdown>
      </li>
    </ul>
  </base-nav>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchQuery: "",
    };
  },
  computed: {
    ...mapState({
      userdetails: (state) => state.user.userdetails,
    }),
  },
  methods: {
    logout: function() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
};
</script>
