import axios from "axios";

const kpiperformance = {
  actions: {
    processkpireference(context, userdata) {
      return new Promise((resolve, reject) => {
        axios({
          url: "/KpiPerformance/ProcessKpiReference",
          data: userdata,
          method: "POST",
        })
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getkpireferencecurrent({ rootState }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/KpiPerformance/GetKpiReferenceCurrent?Username=
              ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getkpireference({ rootState }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/KpiPerformance/GetKpiReference?Username=
              ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getkpireferencebyid({ rootState }, Id) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/KpiPerformance/GetKpiReferenceById?Id=
            ${Id}&Username=
              ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getkpiperformance({ rootState }, { KpiReferenceId, OutletId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/KpiPerformance/GetKpiPerformance?KpiReferenceId=
            ${KpiReferenceId}&OutletId=${OutletId}&Username=
              ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getkpiperformancebyid({ rootState }, Id) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/KpiPerformance/GetKpiPerformanceById?Id=
            ${Id}&Username=
              ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    loginkpiperformance(context, userdata) {
      return new Promise((resolve, reject) => {
        axios({
          url: "/KpiPerformance/LoginKpiPerformance",
          data: userdata,
          method: "POST",
        })
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    processkpiperformancedetails(context, userdata) {
      return new Promise((resolve, reject) => {
        axios({
          url: "/KpiPerformance/ProcessKpiPerformanceDetails",
          data: userdata,
          method: "POST",
        })
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getkpiperformancedetails({ rootState }, KpiPerformanceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/KpiPerformance/GetKpiPerformanceDetails?KpiPerformanceId=
              ${KpiPerformanceId}&Username=
                ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    processkpiperformancecomment(context, userdata) {
      return new Promise((resolve, reject) => {
        axios({
          url: "/KpiPerformance/ProcessKpiPerformanceComment",
          data: userdata,
          method: "POST",
        })
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getkpiperformancecomment({ rootState }, KpiPerformanceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/KpiPerformance/GetKpiPerformanceComment?KpiPerformanceId=
                ${KpiPerformanceId}&Username=
                  ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    processkpiperformanceproductpromo(context, userdata) {
      return new Promise((resolve, reject) => {
        axios({
          url: "/KpiPerformance/ProcessKpiPerformanceProductPromo",
          data: userdata,
          method: "POST",
        })
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    processkpiPerformanceproductcompetitivetemp(context, userdata) {
      return new Promise((resolve, reject) => {
        axios({
          url: "/KpiPerformance/ProcessKpiPerformanceProductCompetitiveTemp",
          data: userdata,
          method: "POST",
        })
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getkpiPerformanceproductcompetitivetemp({ rootState }, {KpiPerformanceId,ProductId}) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/KpiPerformance/GetKpiPerformanceComment?KpiPerformanceId=
                ${KpiPerformanceId}&ProductId=${ProductId}&Username=
                  ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    GetAllOutletVisitPermission({ rootState }, KpiReferenceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/KpiPerformance/GetAllOutletVisitPermission?KpiReferenceId=
            ${KpiReferenceId}&Username=
              ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    PermitOutletVisit({ rootState }, { KpiReferenceId, OutletId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/KpiPerformance/PermitOutletVisit?KpiReferenceId=
            ${KpiReferenceId}&OutletId=${OutletId}&Username=
              ${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    processkpiperformanceshareofshelf(context, userdata) {
      return new Promise((resolve, reject) => {
        axios({
          url: "/KpiPerformance/ProcessKpiPerformanceProductShareofShelf",
          data: userdata,
          method: "POST",
        })
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    processkpiperformanceproductshelfstandard(context, userdata) {
      return new Promise((resolve, reject) => {
        axios({
          url: "/KpiPerformance/ProcessKpiPerformanceShelfStandard",
          data: userdata,
          method: "POST",
        })
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    processkpiperformancecalltoaction(context, userdata) {
      return new Promise((resolve, reject) => {
        axios({
          url: "/KpiPerformance/ProcessKpiPerformanceCalltoAction",
          data: userdata,
          method: "POST",
        })
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};

export default kpiperformance;
