import axios from 'axios';

const supervisor = {   
	actions: {	
       
        getsupervisorbyid(context,Id){
			return new Promise((resolve, reject) => {				
				axios.get(`/Supervisor/GetSupervisorById?Id=${Id}`)
				.then(resp => resp.data)
				.then(respdata => {								
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },				
		searchsupervisor({rootState}, item){
			return new Promise((resolve, reject) => {				
				axios.get(`/Supervisor/SearchSupervisor?SearchItem=${item}&Username=${rootState.auth.token}`)
				.then(resp => resp.data)
				.then(respdata => {										
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
		processsupervisor(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Supervisor/ProcessSupervisor', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
        },        
	}
}

export default supervisor