import axios from 'axios';

const productcategory = {   
	actions: {
		getproductcategory({rootState}){
			return new Promise((resolve, reject) => {				
				axios.get('/Product/GetAllProductCategory?Username='+ rootState.auth.token)
				.then(resp => resp.data)
				.then(respdata => {													
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
       
        getproductcategorybyid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios.get(`/Product/GetProductCategoryById?Id=${Id}&Username=${rootState.auth.token}`)
				.then(resp => resp.data)
				.then(respdata => {								
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },		
		searchproductcategory({rootState}, item){
			return new Promise((resolve, reject) => {				
				axios.get(`/Product/SearchProductCategory?SearchItem=${item}&Username=${rootState.auth.token}`)
				.then(resp => resp.data)
				.then(respdata => {										
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
		processproductcategory(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Product/ProductCategoryRegistration', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
        },        
	}
}

export default productcategory