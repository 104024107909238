import axios from 'axios';

const outlet = {   
	actions: {		
		getoutlet({rootState}){
			return new Promise((resolve, reject) => {				
				axios.get('/Outlet/GetAllOutlet?Username='+ rootState.auth.token)
				.then(resp => resp.data)
				.then(respdata => {													
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},       
        getoutletbyid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios.get(`/Outlet/GetOutletById?Id=${Id}&Username=${rootState.auth.token}`)
				.then(resp => resp.data)
				.then(respdata => {								
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },			
		searchoutlet({rootState}, item){
			return new Promise((resolve, reject) => {				
				axios.get(`/Outlet/SearchOutlet?SearchItem=${item}&Username=${rootState.auth.token}`)
				.then(resp => resp.data)
				.then(respdata => {										
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
		processoutlet(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Outlet/OutletRegistration', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
        },  
		getoutletnotassigned({rootState},UserId){
			return new Promise((resolve, reject) => {				
				axios.get(`/Outlet/GetOutletNotAssigned?Username=${rootState.auth.token}&UserId=${UserId}`)
				.then(resp => resp.data)
				.then(respdata => {													
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	 
		getoutletassigned({rootState},UserId){
			return new Promise((resolve, reject) => {				
				axios.get(`/Outlet/GetOutletAssigned?Username=${rootState.auth.token}&UserId=${UserId}`)
				.then(resp => resp.data)
				.then(respdata => {													
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	 
		getoutletbykpiperformance({rootState},KpiPerformanceId){
			return new Promise((resolve, reject) => {				
				axios.get(`/Outlet/GetOutletByKpiPerformance?KpiPerformanceId=${KpiPerformanceId}&Username=${rootState.auth.token}`)
				.then(resp => resp.data)
				.then(respdata => {								
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
		getoutletformerchandiser({rootState},UserId){
			return new Promise((resolve, reject) => {				
				axios.get(`/Outlet/GetOutletforMerchandiser?Username=${rootState.auth.token}&UserId=${UserId}`)
				.then(resp => resp.data)
				
				.then(respdata => {													
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
		getoutletforkpimodern({rootState},KpiRefrenceId){
			return new Promise((resolve, reject) => {				
				axios.get(`/Outlet/GetOutletForKpiModern?KpiRefrenceId=${KpiRefrenceId}&Username=${rootState.auth.token}`)
				.then(resp => resp.data)
				.then(respdata => {								
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },	
		GetOutletYetPermitedforVisit({rootState},KpiReferenceId){
			return new Promise((resolve, reject) => {				
				axios.get(`/Outlet/GetOutletYetPermitedforVisit?KpiReferenceId=${KpiReferenceId}&Username=${rootState.auth.token}`)
				.then(resp => resp.data)
				.then(respdata => {								
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },	  
		GetOutletYetPermitedforVisitByMerchandiser({rootState},{MerchandiserId,KpiReferenceId}){
			return new Promise((resolve, reject) => {				
				axios.get(`/Outlet/GetOutletYetPermitedforVisitByMerchandiser?MerchandiserId=${MerchandiserId}&KpiReferenceId=${KpiReferenceId}&Username=${rootState.auth.token}`)
				.then(resp => resp.data)
				.then(respdata => {								
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },	    
	}
}

export default outlet