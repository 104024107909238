import axios from "axios";

const product = {
  actions: {
    getproduct({ rootState }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/Product/GetAllProduct?Username=" + rootState.auth.token)
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    GetProductByCateryId({ rootState },CategoryId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/Product/GetProductByCateryId?CategoryId=${CategoryId}&Username=${ rootState.auth.token}`)
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getproductpromo({ rootState }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/Product/GetProductPromo?Username=" + rootState.auth.token)
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getproductnpd({ rootState }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/Product/GetProductNpd?Username=" + rootState.auth.token)
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getproductosa({ rootState }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/Product/GetProductOsa?Username=" + rootState.auth.token)
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getproductosaformerchandiser({ rootState }, KpiPerfomanceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Product/GetProductOsaforMerchandiser?KpiPerfomanceId=${KpiPerfomanceId}&Username=${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getproductpromoformerchandiser({ rootState }, KpiPerfomanceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Product/GetProductPromoforMerchandiser?KpiPerfomanceId=${KpiPerfomanceId}&Username=${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getproductnpdformerchandiser({ rootState }, KpiPerfomanceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Product/GetProductNpdforMerchandiser?KpiPerfomanceId=${KpiPerfomanceId}&Username=${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getbrandshareofshelfformerchandiser({ rootState }, KpiPerfomanceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Brand/GetBrandShareofShelfforMerchandiser?KpiPerfomanceId=${KpiPerfomanceId}&Username=${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getbrandsharestandardformachandiser({ rootState }, KpiPerfomanceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Brand/GetBrandShareStandardforMachandiser?KpiPerfomanceId=${KpiPerfomanceId}&Username=${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getbrandcalltoactionformachandiser({ rootState }, KpiPerfomanceId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Brand/GetBrandCalltoActionforMachandiser?KpiPerfomanceId=${KpiPerfomanceId}&Username=${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getproductbyid({ rootState }, Id) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Product/GetProductById?Id=${Id}&Username=${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    searchproduct({ rootState }, item) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Product/SearchProduct?SearchItem=${item}&Username=${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    processproduct(context, userdata) {
      return new Promise((resolve, reject) => {
        axios({
          url: "/Product/ProductRegistration",
          data: userdata,
          method: "POST",
        })
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    markproductpromo({ rootState }, Id) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Product/MarkProductPromo?Id=${Id}&Username=${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    markproductnpd({ rootState }, Id) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Product/MarkProductNpd?Id=${Id}&Username=${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    CreatePlanogramCategoryOrder(context, userdata) {
      return new Promise((resolve, reject) => {
        axios({
          url: "/Product/CreatePlanogramCategoryOrder",
          data: userdata,
          method: "POST",
        })
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    GetPlanogramCategoryOrder({ rootState }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Product/GetPlanogramCategoryOrder?Username=${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    CreatePlanogramProductOrder(context, userdata) {
      return new Promise((resolve, reject) => {
        axios({
          url: "/Product/CreatePlanogramProductOrder",
          data: userdata,
          method: "POST",
        })
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    GetPlanogramProductOrder({ rootState },ProductCategoryId ) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/Product/GetPlanogramProductOrder?ProductCategoryId=${ProductCategoryId}&Username=${rootState.auth.token}`
          )
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};

export default product;
