<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="CMIH"
      title="CMIH"
    >
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-tv-2 text-primary',
            path: '/dashboard',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Store',
            icon: 'fa fa-warehouse text-blue',
            path: '/outlet',
          }"
        />
        <sidebar-item
          :link="{
            name: 'SKU',
            icon: 'fa fa-cubes text-blue',
            path: '/product',
          }"
          v-if="$store.state.auth.user_role_id == 1"
        />
        <sidebar-item
          :link="{
            name: 'Planogram',
            icon: 'fa fa-cubes text-blue',
            path: '/planogram',
          }"
          v-if="$store.state.auth.user_role_id == 1"
        />

        <sidebar-item
          :link="{
            name: 'SKU Category',
            icon: 'fa fa-cubes text-blue',
            path: '/productcategory',
          }"
          v-if="$store.state.auth.user_role_id == 1"
        />
        <sidebar-item
          :link="{
            name: 'Brand',
            icon: 'fa fa-cubes text-blue',
            path: '/productbrand',
          }"
          v-if="$store.state.auth.user_role_id == 1"
        />
        <sidebar-item
          :link="{
            name: 'MH SKUs',
            icon: 'fa fa-cubes text-blue',
            path: '/channelproduct',
          }"
          v-if="$store.state.auth.user_role_id == 1"
        />
        <sidebar-item
          :link="{
            name: 'Permit Outlet Visit',
            icon: 'fa fa-store text-blue',
            path: '/OutletVisitPermission',
          }"
          v-if="$store.state.auth.user_role_id == 1"
        />
         <sidebar-item
          :link="{
            name: 'KPI Index Details',
            icon: 'fa fa-store text-blue',
            path: '/kpireference',
          }"
          v-if="$store.state.auth.user_role_id == 1"
        />
        <sidebar-item
          :link="{
            name: 'Region',
            icon: 'fa fa-map text-blue',
            path: '/region',
          }"
          v-if="$store.state.auth.user_role_id == 1"
        />
        <sidebar-item
          :link="{
            name: 'Distributor',
            icon: 'fa fa-users text-blue',
            path: '/distributor',
          }"
          v-if="$store.state.auth.user_role_id == 1"
        />        
        <sidebar-item
          :link="{
            name: 'Users',
            icon: 'fa fa-users text-blue',
            path: '/user',
          }"
          v-if="$store.state.auth.user_role_id == 1"
        />
        <sidebar-item
          :link="{
            name: 'Reports',
            icon: 'fa fa-chart-bar text-blue',
            path: '/report',
          }"
          v-if="$store.state.auth.user_role_id == 1"
        />
        <sidebar-item
          :link="{
            name: 'Password Policy',
            icon: 'fa fa-lock text-blue',
            path: '/passwordpolicy',
          }"
          v-if="$store.state.auth.user_role_id == 1"
        />
      </template>
    </side-bar>

    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { FadeTransition } from "vue2-transitions";
export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
  mounted() {},
};
</script>
<style lang="scss"></style>
