import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import auth from './modules/auth'
import app from './modules/app'
import uploader from './modules/uploader'
import user from './modules/user'
import kpi from './modules/kpi'
import productbrand from './modules/productbrand'
import productcategory from './modules/productcategory'
import product from './modules/product'
import skucompetitive from './modules/productcompetitor'
import outlet from './modules/outlet'
import merchandiseroutlet from './modules/merchandiseroutlet'
import kpiperformance from './modules/kpiperformance'
import channelproduct from './modules/channelproduct'
import report from './modules/report'
import region from './modules/region'
import distributor from './modules/distributor'
import supervisor from './modules/supervisor'
import passwordpolicy from './modules/passwordpolicy'

Vue.use(Vuex)


export default new Vuex.Store({
	plugins: [createPersistedState()],
	modules: {
		auth,		
		app,
		product,
		skucompetitive,
		kpi,
		productbrand,
		productcategory,
		outlet,
		merchandiseroutlet,
		kpiperformance,
		channelproduct,
		uploader,
		user,
		report,
		region,
		distributor,
		supervisor,
		passwordpolicy
	}
  })
