import axios from "axios";

const user = {
  state: {
    users: [],
    userdetails: {},
  },
  mutations: {
    users_success(state, payload) {
      state.users = payload;
    },
    userdetails_success(state, payload) {
      state.userdetails = payload;
    },
  },
  actions: {
    GetAllMerchandiser({rootState }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/User/GetAllMerchandiser?Username=" + rootState.auth.token)
          .then((resp) => resp.data)
          .then((respdata) => {           
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getuser({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/User/GetUserByUsername?Username=" + rootState.auth.token)
          .then((resp) => resp.data)
          .then((respdata) => {    
            let user_id = respdata.Id;       
            let token = respdata.EmailAddress;
            let user_fullName = respdata.FullName;
            let user_phone = respdata.PhoneNumber;
            let user_email = respdata.EmailAddress;
            let user_role_id = respdata.RoleId;
            let user_passport = null;
            if (respdata.ImagePath != null) {
              user_passport =
                rootState.auth.baseurl + respdata.ImagePath.substr(1);
            }            
            commit("auth_success", {
              token,
              user_fullName,
              user_phone,
              user_email,
              user_passport,
              user_role_id,
              user_id,
            });
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getusers({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/User/GetAllUser?Username=" + rootState.auth.token)
          .then((resp) => resp.data)
          .then((respdata) => {
            const payload = respdata;
            commit("users_success", payload);
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getuserbyid(context, Id) {
      return new Promise((resolve, reject) => {
        axios
          .get("/User/GetUserById?Id=" + Id)
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getsupervisor({rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/User/GetAllSupervisor?Username=${rootState.auth.token}`)
				.then(resp => resp.data)
				.then(respdata => {													
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
    searchuser(context, item) {
      return new Promise((resolve, reject) => {
        axios
          .get("/User/SearchUser?SearchItem=" + item)
          .then((resp) => resp.data)
          .then((respdata) => {
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    processuser({ commit }, userdata) {
      return new Promise((resolve, reject) => {
        axios({ url: "/User/UserRegistration", data: userdata, method: "POST" })
          .then((resp) => resp.data)
          .then((respdata) => {
            const payload = respdata;
            commit("users_success", payload);
            resolve(respdata);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};

export default user;
