<template>
<div class="main-content">
    <ul class="cb-slideshow">
        <li><span>Image 01</span>
            <div>
                <h3 class="text-hide">Product Merchandising</h3>
            </div>
        </li>
        <li><span>Image 02</span>
            <div>
                <h3 class="text-hide">Product Merchandising</h3>

            </div>
        </li>
        <li><span>Image 03</span>
            <div>
                <h3 class="text-hide">Product Merchandising</h3>
            </div>
        </li>
        <li><span>Image 04</span>
            <div>
                <h3 class="text-hide">Product Merchandising</h3>
            </div>
        </li>
        <li><span>Image 05</span>
            <div>
                <h3 class="text-hide">Product Merchandising</h3>
            </div>
        </li>
        <li><span>Image 06</span>
            <div>
                <h3 class="text-hide">Product Merchandising</h3>
            </div>
        </li>
    </ul>
    <!-- Header -->
    <div class="header py-8 py-lg-8">

    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
        <slide-y-up-transition mode="out-in" origin="center top">
            <router-view></router-view>
        </slide-y-up-transition>
    </div>
    <footer class="py-5">
        <div class="container">
            <div class="row align-items-center justify-content-xl-between">
                <div class="col-xl-6">
                    <div class="copyright text-center text-xl-left text-muted">
                        &copy; {{year}} <a href="#" class="font-weight-bold ml-1">CMIH Unilever Merchandising Project</a>
                    </div>
                </div>
                <div class="col-xl-6">
                    <ul class="nav nav-footer justify-content-center justify-content-xl-end">
                        <li class="nav-item">
                        <li class="nav-item">
                            <router-link to="/privacy" class="nav-link">Privacy Policy</router-link>

                        </li>
                        <li class="nav-item">
                            <a href="#" class="nav-link" target="_blank">Term & Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</div>
</template>

<script>
import {
    SlideYUpTransition
} from 'vue2-transitions'

export default {
    name: 'auth-layout',
    components: {
        SlideYUpTransition
    },
    data() {
        return {
            year: new Date().getFullYear(),
            showMenu: false
        }
    }
}
</script>

<style>
</style>
