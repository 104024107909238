import axios from "axios";
axios.defaults.baseURL = "http://localhost/ProductMerchandisingBe/api/";
const auth = {
  state: {
    status: "",
    token: localStorage.getItem("token") || "",
    user_id: 0,
    user_fullName: "",
    user_phone: "",
    user_email: "",
    user_passport: "",
    user_role_id: "",
    username: "",
    baseurl: "http://localhost/ProductMerchandisingBe",
  },
  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(
      state,
      {
        token,
        user_fullName,
        user_phone,
        user_email,
        user_passport,
        user_role_id,
        user_id,
      }
    ) {
      state.status = "success";
      state.token = token;
      state.user_fullName = user_fullName;
      state.user_phone = user_phone;
      state.user_email = user_email;
      state.user_passport = user_passport;
      state.user_role_id = user_role_id;
      state.user_id = user_id;
    },
    auth_username(state, username) {
      state.status = "yet to logon";
      state.username = username;
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.token = "";
    },
  },
  actions: {
    login({ commit, rootState }, userdata) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        commit("auth_username", userdata.username);
        axios({
          url: "/User/UserAthentication",
          data: userdata,
          method: "POST",
        })
          .then((resp) => resp.data)
          .then((loginuser) => {
            let user_id = loginuser.Id;
            let token = loginuser.EmailAddress;
            let user_fullName = loginuser.FullName;
            let user_phone = loginuser.PhoneNumber;
            let user_email = loginuser.EmailAddress;
            let user_role_id = loginuser.RoleId;
            let user_passport = null;
            if (loginuser.ImagePath != null) {
              user_passport =
                rootState.auth.baseurl + loginuser.ImagePath.substr(1);
            }
            localStorage.setItem("token", token);
            // Add the following line:
            axios.defaults.headers.common["Authorization"] = token;
            commit("auth_success", {
              token,
              user_fullName,
              user_phone,
              user_email,
              user_passport,
              user_role_id,
              user_id,
            });
            resolve(loginuser);
          })
          .catch((err) => {
            commit("auth_error");
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },
    retrievepassword(context, Username) {
      return new Promise((resolve, reject) => {
        axios.get(`/User/RetriveUserPassword?Username=${Username}`)
          .then((resp) => resp.data)
          .then((loginuser) => {
            resolve(loginuser);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    changepassword(context, userdata) {
      return new Promise((resolve, reject) => {
        axios({ url: "/User/Changepassword", data: userdata, method: "POST" })
          .then((resp) => resp.data)
          .then((loginuser) => {
            resolve(loginuser);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit("logout");
        localStorage.removeItem("token");
        delete axios.defaults.headers.common["Authorization"];
        resolve();
      });
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
  },
};

export default auth;
