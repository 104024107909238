import axios from 'axios';

const kpi = {
    state: {  
		kpi : null
	},	
	mutations: {		
		kpi_success(state, payload){			
			state.kpi = payload	
		},			
	},
	actions: {
		getkpi({commit,rootState}){
			return new Promise((resolve, reject) => {				
				axios.get('/Product/GetAllKpi?Username='+ rootState.auth.token)
				.then(resp => resp.data)
				.then(respdata => {	
					const payload = respdata;	
					commit('kpi_success',payload);							
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
       
        getkpibyid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios.get(`/Product/GetKpiById?Id=${Id}&Username=${rootState.auth.token}`)
				.then(resp => resp.data)
				.then(respdata => {								
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },			
		searchkpi({rootState}, item){
			return new Promise((resolve, reject) => {				
				axios.get(`/Product/SearchKpi?SearchItem=${item}&Username=${rootState.auth.token}`)
				.then(resp => resp.data)
				.then(respdata => {										
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
		processkpi({commit}, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Product/KpiRegistration', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('kpi_success',payload);					
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
        },        
	}
}

export default kpi