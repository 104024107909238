import axios from 'axios';

const productbrand = {   
	actions: {
		getproductbrand({rootState}){
			return new Promise((resolve, reject) => {				
				axios.get('/Brand/GetAllProductBrand?Username='+ rootState.auth.token)
				.then(resp => resp.data)
				.then(respdata => {													
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
       
        getproductbrandbyid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios.get(`/Brand/GetProductBrandById?Id=${Id}&Username=${rootState.auth.token}`)
				.then(resp => resp.data)
				.then(respdata => {								
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },		
		searchproductbrand({rootState}, item){
			return new Promise((resolve, reject) => {				
				axios.get(`/Brand/SearchProductBrand?SearchItem=${item}&Username=${rootState.auth.token}`)
				.then(resp => resp.data)
				.then(respdata => {										
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
		processproductbrand(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/Brand/ProductBrandRegistration', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
        },        
	}
}

export default productbrand