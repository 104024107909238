
import Vue from 'vue'

import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import store from './store/index'
import Axios from 'axios'
import moment from 'moment'
import SDockDashboard from './plugins/sdock-dashboard'



Vue.prototype.$http = Axios;

Vue.config.productionTip = false
Vue.filter('formatDate', function(value){
  if(value){
    return moment(String(value)).format('DD/MM/YYYY')
  }
});

Vue.filter('formatDateTime', function(value){
  if(value){
    return moment(String(value)).format('DD/MM/YYYY hh:mm')
  }
});

Vue.use(SDockDashboard)
new Vue({ 
  router, 
  store,
  render: h => h(App)
}).$mount('#app')
