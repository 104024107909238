var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":"CMIH","title":"CMIH"}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          icon: 'ni ni-tv-2 text-primary',
          path: '/dashboard',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Store',
          icon: 'fa fa-warehouse text-blue',
          path: '/outlet',
        }}}),(_vm.$store.state.auth.user_role_id == 1)?_c('sidebar-item',{attrs:{"link":{
          name: 'SKU',
          icon: 'fa fa-cubes text-blue',
          path: '/product',
        }}}):_vm._e(),(_vm.$store.state.auth.user_role_id == 1)?_c('sidebar-item',{attrs:{"link":{
          name: 'Planogram',
          icon: 'fa fa-cubes text-blue',
          path: '/planogram',
        }}}):_vm._e(),(_vm.$store.state.auth.user_role_id == 1)?_c('sidebar-item',{attrs:{"link":{
          name: 'SKU Category',
          icon: 'fa fa-cubes text-blue',
          path: '/productcategory',
        }}}):_vm._e(),(_vm.$store.state.auth.user_role_id == 1)?_c('sidebar-item',{attrs:{"link":{
          name: 'Brand',
          icon: 'fa fa-cubes text-blue',
          path: '/productbrand',
        }}}):_vm._e(),(_vm.$store.state.auth.user_role_id == 1)?_c('sidebar-item',{attrs:{"link":{
          name: 'MH SKUs',
          icon: 'fa fa-cubes text-blue',
          path: '/channelproduct',
        }}}):_vm._e(),(_vm.$store.state.auth.user_role_id == 1)?_c('sidebar-item',{attrs:{"link":{
          name: 'Permit Outlet Visit',
          icon: 'fa fa-store text-blue',
          path: '/OutletVisitPermission',
        }}}):_vm._e(),(_vm.$store.state.auth.user_role_id == 1)?_c('sidebar-item',{attrs:{"link":{
          name: 'KPI Index Details',
          icon: 'fa fa-store text-blue',
          path: '/kpireference',
        }}}):_vm._e(),(_vm.$store.state.auth.user_role_id == 1)?_c('sidebar-item',{attrs:{"link":{
          name: 'Region',
          icon: 'fa fa-map text-blue',
          path: '/region',
        }}}):_vm._e(),(_vm.$store.state.auth.user_role_id == 1)?_c('sidebar-item',{attrs:{"link":{
          name: 'Distributor',
          icon: 'fa fa-users text-blue',
          path: '/distributor',
        }}}):_vm._e(),(_vm.$store.state.auth.user_role_id == 1)?_c('sidebar-item',{attrs:{"link":{
          name: 'Users',
          icon: 'fa fa-users text-blue',
          path: '/user',
        }}}):_vm._e(),(_vm.$store.state.auth.user_role_id == 1)?_c('sidebar-item',{attrs:{"link":{
          name: 'Reports',
          icon: 'fa fa-chart-bar text-blue',
          path: '/report',
        }}}):_vm._e(),(_vm.$store.state.auth.user_role_id == 1)?_c('sidebar-item',{attrs:{"link":{
          name: 'Password Policy',
          icon: 'fa fa-lock text-blue',
          path: '/passwordpolicy',
        }}}):_vm._e()],1)],2),_c('div',{staticClass:"main-content",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('div',{on:{"click":_vm.toggleSidebar}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }