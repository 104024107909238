import axios from 'axios';
axios.defaults.baseURL = 'http://localhost/ProductMerchandisingBe/api/';
const app = {
    state: {		 
		roleData : null,
		channelData : null,
		regionData : null				
	},	
	mutations: {		
		roleData_success(state, result){			
			state.roleData = result			
		},
		channelData_success(state, result){			
			state.channelData = result			
		},		
	},
	actions: {	
		GetAppSummary({rootState}){
			return new Promise((resolve, reject) => {				
				axios.get(`/App/GetAppSummary?Username=${rootState.auth.token}`)
				.then(resp => resp.data)
				.then(respdata => {										
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
        getroles({commit}){
			return new Promise((resolve, reject) => {				
				axios.get('/App/GetRoles')
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('roleData_success',payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
		getchannel({commit}){
			return new Promise((resolve, reject) => {				
				axios.get('/App/GetChannel')
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('channelData_success',payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },		
		getgoogleaddress({commit}){
			return new Promise((resolve, reject) => {				
				axios.get('/App/GetRegion')
				.then(resp => resp.data)
				.then(respdata => {
					const payload = respdata;					
					commit('regionData_success',payload);					
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
		getlogs(){
			return new Promise((resolve, reject) => {				
				axios.get('/App/GetLogs')
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
		deletelog(context,Id){
			return new Promise((resolve, reject) => {				
				axios.get(`/App/DeleteLog?Id=${Id}`)
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },
	}	
}

export default app