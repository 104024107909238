import axios from 'axios';

const productcompetitor = {   
	actions: {
		getproductcompetitor({rootState},ProductId){
			return new Promise((resolve, reject) => {				
				axios.get(`/ProductCompetitor/GetAllProductCompetitor?ProductId=${ProductId}&Username=${rootState.auth.token}`)
				.then(resp => resp.data)
				.then(respdata => {												
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
       
        getproductcompetitorbyid({rootState},Id){
			return new Promise((resolve, reject) => {				
				axios.get(`/ProductCompetitor/GetProductCompetitorById?Id=${Id}&Username=${rootState.auth.token}`)
				.then(resp => resp.data)
				.then(respdata => {								
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
        },			
		searchproductcompetitor({rootState},{ProductId, item}){
			return new Promise((resolve, reject) => {				
				axios.get(`/ProductCompetitor/SearchProductCompetitor?SearchItem=${item}&ProductId=${ProductId}&Username=${rootState.auth.token}`)
				.then(resp => resp.data)
				.then(respdata => {										
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	
		processproductcompetitor(context, userdata){
			return new Promise((resolve, reject) => {				
				axios({url: '/ProductCompetitor/ProductCompetitorRegistration', data: userdata, method: 'POST' })
				.then(resp => resp.data)
				.then(respdata => {									
					resolve(respdata)
				})
				.catch(err => {
					reject(err)
				})
			})
        }, 
		getproductcompetitorforkpiperfomance({rootState},{ProductId,KpiPerformanceId}){
			return new Promise((resolve, reject) => {				
				axios.get(`/ProductCompetitor/GetAllProductCompetitorforKpiPerfomance?ProductId=${ProductId}&KpiPerformanceId=${KpiPerformanceId}&Username=${rootState.auth.token}`)
				.then(resp => resp.data)
				.then(respdata => {												
					resolve(respdata)
				})
				.catch(err => {									
					reject(err)
				})
			})
		},	       
	}
}

export default productcompetitor